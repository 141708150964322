<template>
    <div>
        <Form :model="formData" label-position="left" :label-width="150">
            <div style="margin: 0 10px; width: 60%">
                <div class="equiment-title">基本信息</div>
                <FormItem>
                    <span slot="label" class="validate">组织名称:</span>
                    <Input placeholder="请输入组织名称" v-model.trim="formData.orgName" :maxlength="50"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">选择地区:</span>
                    <div class="cascader-div">
                        <Select v-model="formData.addr.provinceCode" transfer style="width: 15%; margin-right: 10px" :max-tag-count="1" placeholder="请选择省" filterable @on-change="changeProvince">
                            <Option :value="item.code" v-for="(item, index) in provinceList" :key="index">{{ item.label }}</Option>
                        </Select>
                        <Select
                            v-model="formData.addr.cityCode"
                            transfer
                            style="width: 15%; margin-right: 10px"
                            :max-tag-count="1"
                            placeholder="请选择市"
                            filterable
                            :disabled="cityDisabled"
                            @on-change="changeCity"
                        >
                            <Option :value="item.code" v-for="(item, index) in cityList" :key="index">{{ item.label }}</Option>
                        </Select>
                        <Select
                            v-model="formData.addr.countyCode"
                            transfer
                            style="width: 15%; margin-right: 10px"
                            :max-tag-count="1"
                            placeholder="请选择区"
                            :disabled="regionDisabled"
                            filterable
                            @on-change="changeRegion"
                        >
                            <Option :value="item.code" v-for="(item, index) in regionList" :key="index">{{ item.label }}</Option>
                        </Select>
                        <Select
                            v-model="formData.addr.streetCode"
                            transfer
                            style="width: 15%; margin-right: 10px"
                            :max-tag-count="1"
                            placeholder="请选择街道/乡镇"
                            :disabled="streeDisabled"
                            filterable
                            @on-change="changeStree"
                        >
                            <Option :value="item.code" v-for="(item, index) in streeList" :key="index">{{ item.label }}</Option>
                        </Select>
                        <Select v-model="formData.addr.communityCode" transfer style="width: 15%" :max-tag-count="1" placeholder="请选择社区" :disabled="projectDisabled" filterable>
                            <Option :value="item.code" v-for="(item, index) in projectList" :key="index">{{ item.label }}</Option>
                        </Select>
                    </div>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">所属组织维度:</span>
                    <Select :disabled="isSelectDisabled" v-model="formData.dimensionId" placeholder="请选择组织维度" @on-select="changeSelect">
                        <Option :value="item.id" v-for="(item, index) in codeOptions" :key="index">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <!-- <FormItem >
          <span slot="label" class="validate">上级组织:</span>
          <div>
               <Tree :disabled="isDisabled" @on-select-change="changeTree" :data="orgOptions" style="border:1px solid #dcdee2;max-height:500px;overflow-y:scroll;padding-left:10px"></Tree>
          </div>   
        </FormItem> -->
                <FormItem>
                    <span slot="label">详细地址:</span>
                    <Input placeholder="请输入详细地址" v-model.trim="formData.addr.addr" :maxlength="50"></Input>
                </FormItem>

                <FormItem>
                    <span slot="label">统一社会信用代码:</span>
                    <Input placeholder="请输入统一社会信用代码" v-model.trim="formData.qualification.creditCode" :maxlength="50"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label">营业执照图片:</span>
                    <LiefengUpload v-if="avatarShow" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" ref="liefengUpload" :defaultList="defaultList"></LiefengUpload>
                </FormItem>
                <FormItem>
                    <span slot="label">法人代表:</span>
                    <Input placeholder="请输入法人代表" v-model.trim="formData.qualification.legalRepresentative" :maxlength="50"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label">备案号:</span>
                    <Input placeholder="请输入备案号" v-model.trim="formData.qualification.recordCode" :maxlength="50"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label">平台合作协议:</span>
                    <LiefengUpload v-if="avatarShowTow" type="file" ref="liefengUploadTwo" :defaultList="defaultTwoList"></LiefengUpload>
                    <Button type="dashed" @click="removeDefault">重新上传平台合作协议</Button>
                </FormItem>
                <div class="equiment-title">其他信息</div>
                <FormItem>
                    <span slot="label">联系电话:</span>
                    <Input placeholder="请输入联系电话" v-model.trim="formData.qualification.tel" :maxlength="20"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label">经纬度:</span>
                    <Input placeholder="请输入经纬度" v-model.trim="formData.addr.longitude" :maxlength="40"></Input>
                </FormItem>
                <!-- <FormItem>
          <span slot="label">纬度:</span>
          <Input placeholder="请输入经度" v-model.trim="formData.addr.latitude" :maxlength="20"></Input>
        </FormItem> -->
                <FormItem>
                    <Button type="info" @click="openMap">选择经纬度</Button>
                </FormItem>
                <FormItem>
                    <span slot="label">排序:</span>
                    <InputNumber :min="0" v-model="formData.seq"></InputNumber>
                </FormItem>
            </div>
        </Form>
        <LiefengModal class="map" :fullscreen="false" width="80%" title="选择经纬度" @input="mapFn" :value="mapStatus">
            <template #contentarea style="width: 100%; height: 700px">
                <Map :showNum="showNum" :localName="localName" :center="center" @clickMap="clickMap"></Map>
            </template>
            <template #toolsbar>
                <Button
                    type="info"
                    style="margin-right: 10px"
                    @click="
                        mapTip = true
                        mapFn(false)
                    "
                >
                    取消
                </Button>
                <Button type="primary" @click="saveMap">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload"
import LiefengModal from "@/components/LiefengModal"
import Map from "./map"
export default {
    props: {
        newflag: Boolean,
        codeOptions: Array,
        formData: Object,
        isShow: Number,
    },
    //import引入的组件需要注入到对象中才能使用
    components: { LiefengUpload, Map, LiefengModal },
    data() {
        //这里存放数据
        return {
            orgOptions: [],
            isDisabled: true,
            isSelectDisabled: false,
            //   省
            province: "",
            provinceList: [], //存放城市列表
            // 市
            city: "",
            cityDisabled: true,
            cityList: [], //存放城市列表
            //  区
            region: "",
            regionDisabled: true,
            regionList: [], // 存放区的列表
            //   街道
            stree: "",
            streeDisabled: true,
            streeList: [], //存放街道的列表

            // 区
            project: "",
            projectDisabled: true,
            projectList: [], //存放区的列表
            problem: {},
            chooseLocal: [],
            regionCode: "",
            cityCode: "",

            // 图片组件显示隐藏
            avatarShow: true,
            avatarShowTow: true,
            // 上传图片列表
            defaultList: [],
            defaultTwoList: [],

            // 地图模态框
            mapStatus: false,
            mapTip: false,
            showNum: 0,
            localName: "",
            center: {
                lat: "",
                lng: "",
            },
            clickCenter: {
                lat: "",
                lng: "",
            },
            // showMap:0
        }
    },
    //方法集合
    methods: {
        changeTree(val) {
            this.$emit("changeTree", val)
        },
        changeSelect(val) {
            this.isDisabled = false
            // this.getTree(val.value)
        },
        getTree(data) {
            this.$Message.loading({
                content: "正在加载上级组织数据，请稍等...",
                duration: 0,
                background: true,
            })
            this.$get("/orgzz/pc/organization/getOrgTreeByDimensionId", {
                oemCode: parent.vue.oemInfo.oemCode,
                dimensionId: data,
            }).then(res => {
                if (res.code == 200) {
                    this.orgOptions = []
                    this.orgOptions = this.forMatterTree(res.dataList)
                    this.isDisabled = false
                    this.$Message.destroy()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    this.$Message.destroy()
                }
            })
        },
        forMatterTree(data) {
            let that = this
            for (var i = 0; i < data.length; i++) {
                if (this.formData.parentId != "" && this.newflag == false) {
                    data[i].expand = true
                    data[i].disabled = true
                    data[i].disableCheckbox = true
                    data[i].title = data[i].name
                    data[i].value = data[i].id
                    if (this.formData.parentId == data[i].parentId) {
                        data[i].selected = true
                    }
                } else {
                    data[i].title = data[i].name
                    data[i].value = data[i].id
                }
                if (data[i].children && data[i].children.length != 0) {
                    that.forMatterTree(data[i].children)
                }
            }
            return data
        },
        // 移除按钮
        removeDefault() {
            if (this.newflag) {
                if (this.$refs.liefengUploadTwo.uploadList.length == 0) {
                    this.$Message.warning({
                        content: "暂无可清空的文件",
                        background: true,
                    })
                    return
                } else {
                    this.$refs.liefengUploadTwo.uploadList = []
                    this.avatarShowTow = false
                    this.$nextTick(() => {
                        this.avatarShowTow = true
                    })
                }
            } else {
                if (this.defaultTwoList.length == 0 && this.$refs.liefengUploadTwo.uploadList.length == 0) {
                    this.$Message.warning({
                        content: "暂无可清空的文件",
                        background: true,
                    })
                    return
                } else {
                    this.defaultTwoList = []
                    this.$refs.liefengUploadTwo.uploadList = []
                    this.avatarShowTow = false
                    this.$nextTick(() => {
                        this.avatarShowTow = true
                    })
                }
            }
        },
        // 点击地图传出来的经纬度
        clickMap(val) {
            this.clickCenter.lng = val.lng
            this.clickCenter.lat = val.lat
        },
        // 选择经纬度确定按钮
        saveMap() {
            this.mapStatus = false
            if (this.clickCenter.lng != "" && this.clickCenter.lat != "") {
                this.formData.addr.longitude = this.clickCenter.lng + "," + this.clickCenter.lat
            }
        },
        // 点击选择经纬度按钮
        openMap() {
            this.mapStatus = true
            this.showNum = this.showNum + 1
            if (this.formData.addr.longitude != "") {
                this.center.lng = this.formData.addr.longitude
            } else {
                this.center.lng = ""
            }
            if (this.formData.addr.latitude != "") {
                this.center.lat = this.formData.addr.latitude
            } else {
                this.center.lat = ""
            }
            this.isName()
        },
        isName() {
            this.localName = ""
            if (this.formData.addr.provinceCode != "") {
                this.provinceList.map(item => {
                    if (item.code == this.formData.addr.provinceCode) {
                        this.localName = item.label
                    }
                })
            }
            if (this.formData.addr.cityCode != "") {
                this.cityList.map(item => {
                    if (item.code == this.formData.addr.cityCode) {
                        this.localName = this.localName + item.label
                    }
                })
            }
            if (this.formData.addr.countyCode != "") {
                this.regionList.map(item => {
                    if (item.code == this.formData.addr.countyCode) {
                        this.localName = this.localName + item.label
                    }
                })
            }
            if (this.formData.addr.streetCode != "") {
                this.streeList.map(item => {
                    if (item.code == this.formData.addr.streetCode) {
                        this.localName = this.localName + item.label
                    }
                })
            }
            if (this.formData.addr.communityCode != "") {
                this.projectList.map(item => {
                    if (item.code == this.formData.addr.communityCode) {
                        this.localName = this.localName + item.label
                    }
                })
            }
            console.log(this.localName)
        },
        // 关闭地图模态框
        // 关闭模态框
        mapFn(status) {
            if (!status && this.mapTip) {
                this.mapStatus = status
                this.mapTip = false
            } else {
                this.mapStatus = status
            }
        },
        changeProvince(code,status) {
            this.cityDisabled = false
            this.getPulishData(code, "cityList", "city")
            if(status) return
            this.formData.addr.cityCode = ""
            this.formData.addr.countyCode = ""
            this.formData.addr.streetCode = ""
            this.formData.addr.communityCode = ""
        },
        // 选择城市回调事件
        changeCity(code,status) {
            this.regionDisabled = false
            this.getPulishData(code, "regionList", "region")
            if(status) return
            this.formData.addr.countyCode = ""
            this.formData.addr.streetCode = ""
            this.formData.addr.communityCode = ""
        },
        // 选择区的回调事件
        changeRegion(code,status) {
            if (this.regionCode == "") {
                this.streeDisabled = false
                this.getPulishData(code, "streeList", "stree")
            } else {
                // if (this.regionCode == code) {
                //   this.streeDisabled = false;
                //   this.getPulishData(code, "streeList", "stree");

                // } else {
                this.streeList = []
                this.projectList = []
                this.stree = []
                this.project = []
                this.projectDisabled = true
                this.getPulishData(code, "streeList", "stree")
                // }
            }
            this.regionCode = code
            if(status) return
            this.formData.addr.streetCode = ""
            this.formData.addr.communityCode = ""
        },
        // 选择街道的回调事件
        changeStree(code,status) {
            if (code != undefined) {
                this.projectDisabled = false
                
                this.getPulishData(code, "projectList", "project")

                if(status) return
                this.formData.addr.communityCode = ""
            }
        },
        // 获取发布范围列表
        getPulishData(code, list, model) {
            if (this.newflag) {
                this.$get("/orgzz/pc/organization/selectOrganizationByOrgCode", {
                    orgCode: code,
                }).then(res => {
                    if (res.code == 200) {
                        this[list] = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                code: item.orgCode,
                                parentId: item.parentId,
                                orgId: item.orgId,
                            }
                        })
                    }
                })
            } else {
                this.$get("/orgzz/pc/organization/selectOrganizationByOrgCode", {
                    orgCode: code,
                }).then(res => {
                    if (res.code == 200) {
                        this[list] = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                code: item.orgCode,
                                parentId: item.parentId,
                                orgId: item.orgId,
                            }
                        })
                    }
                })
            }
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    async created() {
        console.log('表单内拿到的数据',this.formData);
        await this.getPulishData("CN", "provinceList", "province")
        // await this.getPulishData("44", "cityList", "city");
        // await this.getPulishData('2', "regionList", "region");
        // await this.getPulishData('3', "streeList", "stree");
    },
    watch: {
        isShow: {
            handler(val, newVal) {
                if (val) {
                    if (!this.formData.addr.provinceCode || this.formData.addr.provinceCode == "") {
                        this.formData.addr.provinceCode = "44"
                    }
                    console.log(this.formData.addr);
                    this.changeProvince(this.formData.addr.provinceCode,true)
                    this.changeCity(this.formData.addr.cityCode,true)
                    this.changeRegion(this.formData.addr.countyCode,true)
                    this.changeStree(this.formData.addr.streetCode,true)
                    if (this.formData.dimensionId == "") {
                        this.isDisabled = true
                        this.orgOptions = []
                        this.isSelectDisabled = false
                    } else {
                        console.log("如果不为空", val)
                        this.orgOptions = []
                        //  this.getTree(this.formData.dimensionId)
                        this.isDisabled = false
                        this.isSelectDisabled = true
                    }
                    if (this.formData.longitude && this.formData.longitude != "") {
                        this.formData.longitude = this.formData.longitude + "," + this.formData.latitude
                        console.log(this.formData.longitude)
                    }
                }
            },
        },
        defaultList: {
            handler(val, newVal) {
                if (val) {
                    this.avatarShow = false
                    this.$nextTick(() => {
                        this.avatarShow = true
                    })
                    this.avatarShowTow = false
                    this.$nextTick(() => {
                        this.avatarShowTow = true
                    })
                }
            },
        },
          formData:{
             handler(val,newVal){
                console.log('表单数据变化',val);
             },
             immediate: true,
             deep:true
         },
    },
}
</script>
<style lang='less' scoped>
//编写css
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.equiment-title {
    position: relative;
    padding-left: 20px;
    margin: 10px 0;
    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 5px;
        height: 20px;
        background-color: #2db7f5;
    }
}
.map {
    /deep/.ivu-icon-ios-close {
        display: none;
    }
}
</style>